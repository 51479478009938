import { InitialData } from './../../types/web-worker.model';
// Angular Core
import { Component, OnDestroy, HostListener, OnInit ,Renderer2,ElementRef, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

// Services
import { TranslateService } from "@ngx-translate/core";
import { OAuthService } from "angular-oauth2-oidc";
import {
    GoogleTagManagerService,
    GTMCustomEvents,
    GTMLayer,
} from "../../vto/services/gtm.service";
import { DebugService } from "../../services/debug.service";

// Typings
import {
    authConfig,
    discoverDocumentOverride,
    discoverDocumentOverrideSignUp,
} from "../../configs/auth.config";
import { ZeissIdToken, ZeissIdBase } from "visauto-auth";
import {
    FRAMEID_FLAG,
    SESSIONID_FLAG,
    Language,
} from "../../configs/constant.flags";

// Environment
import { ConsumerAppEnvironment as environment } from "visenvironment";

// Components
import { ApplicationInsightsService } from "../../services/applicationInsights.Service";
import { MatDialog } from "@angular/material/dialog";
import { LanguageSelectionDialogComponent } from "../../dialogs/language-selection/language-selection.dialog.component";
import { startWith, delay, map, tap, distinctUntilChanged } from "rxjs/operators";
import { AvatarCreationSession, AvatarSource } from "../../vto/models/avatarcreationsession";
import { FAQPageComponent } from "../../pages/FAQ/faq.page.component";
import { ScriptLoaderService } from "../../services/script-loader.service";
import { BehaviorSubject, combineLatest, fromEvent, merge, of, Subscription } from "rxjs";
import { PrivacyDialogComponent } from "../../dialogs/privacy/privacy.dialog.component";
import { IntroDialogComponent } from "../../vto/dialogs/intro/intro.dialog.component";

import { ViewerFactoryService } from '../../vto/services/viewer.factory.service';
import { InteractionType, LoginInteraction, StatisticService } from '../../services/statistic.service';
import { LanguageService } from "../../services/language.service";
import { AppConfigService } from "../../services/appConfig.service";
import { DataService,BinaryType } from '../../vto/services/data.service';
import { SessionService } from '../../vto/services/session.service';
import { DOCUMENT } from '@angular/common';
import { CameraOrbitParams, CameraView, DualCameraSettings, SingleCameraSettings,  TakeScreenshotProps } from '@vision/3d-viewer';


@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    private consentManagerInstance: string;

    private timerId: number;

    public ecpLogo$: BehaviorSubject<any> = new BehaviorSubject(null);

    public mockedLogosIndex: number = 0;

    private transformLanguage: string;

    private initial$ = of(window.innerWidth > 640 ? false : true);
    private resize$ = fromEvent(window, "resize").pipe(
        map((event: any) => {
            return event.target.innerWidth > 640 ? false : true;
        })
    );
    private queryParams$: Subscription;

    public mobile$ = merge(this.resize$, this.initial$).pipe(
        distinctUntilChanged()
    );

    public isVCLDLanguageSet:boolean=false;

    private worker!: Worker;
    private LAST_ACTION_STORE_KEY: string = "lastAction";

    private keyFunctionCodeArray: number[] = [37, 38, 39, 40];

    private languageSubscription: Subscription;

    constructor(
        private translate: TranslateService,
        private oAuth: OAuthService,
        private debug: DebugService,
        // private contactCard: MatBottomSheet,
        private dialogHost: MatDialog,
        private router: Router,
        private appIn: ApplicationInsightsService,
        private gtm: GoogleTagManagerService,
        private scriptLoader: ScriptLoaderService,
        private factory: ViewerFactoryService,
        private stats: StatisticService,
        private language: LanguageService,
        private appConfig: AppConfigService,
        private route: ActivatedRoute,
        private _datacache: DataService,
        public _session: SessionService,
        @Inject(DOCUMENT) private _document: Document,
        private renderer: Renderer2,
        private element: ElementRef,
        private languages: LanguageService
    ) {
        let lang = "en-US";
        this.translate.setDefaultLang(lang);

        this.configureOAuth();
        let availableLanguages = this.language.getLanguages;
        // check for direct match
        let directMatch = availableLanguages.find(
            (l) => l.code === navigator.language
        )?.code;
        let inderectMatch = availableLanguages.find(
            (l) => l.code.split("-")[0] == navigator.language
        )?.code;
        let browserLang = directMatch ? directMatch : inderectMatch;

        this.debug.log(
            `Browser uses %c${browserLang} %clangugae`,
            "color: green",
            ""
        );

        if (localStorage.getItem("vcldlang")) {
            lang = localStorage.getItem("vcldlang");
            this.isVCLDLanguageSet=true;
        } else {
            lang = browserLang ?? "en-US";
        }

        this.translate.use(lang);
        localStorage.setItem("vcldlang", lang);

        this.gtm.add = { [GTMLayer.pageLanguage]: lang };
        this.gtm.push();

        if(navigator.userAgent.indexOf('iPhone') > -1 )
        {
            document
            .querySelector("[name=viewport]")
            .setAttribute("content","width=device-width, initial-scale=1, maximum-scale=1");
        }
    }

    public sessionAvailable$ = combineLatest([
        this._session.selectedSession$,
        this._session.sessionsList$,
        this._session.singleOptician$,
    ]).pipe(
        startWith<[AvatarCreationSession, AvatarCreationSession[], boolean]>([
            null,
            null,
            false,
        ]),
        delay(0),
        tap(async ([selected, sessions, allSameOptician]) => {
            if (!selected && allSameOptician) {
                this.setEcpLogo(sessions[0]);
            } else if (selected) {
                this.setEcpLogo(selected);
            } else {
                this.removeEcpLogo();
            }
        }),
        delay(0)
    );

    // bind listner to keypress and click to track user's activity.
    @HostListener("document:keypress")
    @HostListener("document:click")
    public resetIdleTimer(): void {
        if (this.worker) {
            localStorage.setItem(
                this.LAST_ACTION_STORE_KEY,
                Date.now().toString()
            );
            this.worker.postMessage({ secondsIdle: 0 });
        }
    }

    @HostListener("wheel", ["$event"])
    onMouseWheel(event: WheelEvent) {
        this.removeElement();
    }

    @HostListener("touchstart")
    onTouchStart(): void {
        this.removeElement();
    }

    @HostListener("document:keydown")
    onKeyPress(event): void {
        if (this.keyFunctionCodeArray.includes(event?.keyCode)) {
            this.removeElement();
        }
    }

    async ngOnInit() {
        await this.language.fetchLanguages();
        this.queryParams$ = this.route.queryParams.subscribe((params) => {
            if (params.language && !this.isVCLDLanguageSet) {
                let availableLanguages = this.language.getLanguages;
                let directMatch = availableLanguages.find(
                    (l) => l.code === params.language
                )?.code;
                let inderectMatch = availableLanguages.find(
                    (l) => l.code.split("-")[0] == params.language
                )?.code;
                let lang = directMatch ? directMatch : inderectMatch;
                if (lang) {
                    this.translate.use(lang);
                    localStorage.setItem("vcldlang", lang);
                }
            }
        });

        // setup webworker listner for logout action.
        this.setupWebWorker();

        // initialize webworker with configurations.
        this.pushInitialWorkerData();

        this.languageSubscription = this.translate.onLangChange.subscribe(e => {
            this.checkScriptLoader(e.lang.split('-')[0] === 'nn' ? 'no' : e.lang.split('-')[0]);
        });
    }
    
    public ngOnDestroy() {
        if (this.queryParams$) {
            this.queryParams$.unsubscribe();
        }

        if(this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }
    }

    private checkScriptLoader(lang: string) {
        const selector = 'script[src^="https://cdn.cookielaw"]';
        this._document.head.querySelectorAll<HTMLScriptElement>(selector).forEach(script => script.remove());

        const div = this._document.body.querySelector('div#onetrust-consent-sdk');
        if(div) {
            div.remove();
        }
       
        const newScript = this._document.createElement('script');
        newScript.type = 'text/javascript';
        newScript.id = 'cookie_manager';
        newScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        newScript.setAttribute('data-language', lang);
        newScript.setAttribute('charset', 'UTF-8');
        newScript.setAttribute('data-domain-script', '8993cba0-8683-43f1-9904-d63a3e023a9c-test');

        this._document.head.appendChild(newScript);
    }


    // pass initial configuration data to web-worker.
    private pushInitialWorkerData() {
        let dataObj = {} as InitialData;
        const storageItem = localStorage.getItem(this.LAST_ACTION_STORE_KEY);

        if (storageItem) {
            dataObj.lastAction = parseInt(storageItem);
        } else {
            if (this.isAuthenticated)
                localStorage.setItem(
                    this.LAST_ACTION_STORE_KEY,
                    Date.now().toString()
                );
            dataObj.lastAction = Date.now();
        }

        // get max idle time from environment file.
        dataObj.minutsUntilLogout =
            environment.authentication.autoLogoutInMinutes || 30;
        this.worker.postMessage({ initialData: dataObj });
    }

    private setupWebWorker() {
        if (typeof Worker !== "undefined") {
            this.worker = new Worker(
                new URL("../../web-workers/auto-logout.worker", import.meta.url)
            );
            this.worker.onmessage = ({ data }): void => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (data.logout && this.oAuth.hasValidIdToken()) {
                    this.oAuth.logOut();
                    localStorage.removeItem(this.LAST_ACTION_STORE_KEY);
                }
            };
        }
    }

    public get terms() {
        return this.appConfig.appLinks["terms_and_conditions"];
    }

    public get zeissLogo() {
        return environment.content.logo;
    }

    public get isDebugModeEnabled(): boolean {
        return this.debug.isDebugMode;
    }

    public onClickOpticianBranding() {
        this.mockedLogosIndex++;
    }

    private async setEcpLogo(session: AvatarCreationSession) {
        const v = session;
        if (v) {
            const b = await this._datacache.getBinary(
                BinaryType.ECP_LOGO,
                v.opticianId,
                true               
            );
            if (b) {
                const x = b;
                this.ecpLogo$.next(x);
            }
            this.gtm.add = {
                [GTMLayer.opticianId]: v.opticianId,
                [GTMLayer.opticianName]: v.opticianName,
            };
            this.gtm.push();
        }
    }

    private removeEcpLogo() {
        this.ecpLogo$.next(null);
    }

    removeElement() {
        const elements = document.getElementsByClassName("mdc-tooltip");
        if (elements?.length > 0) {
            this.renderer.removeChild(this.element.nativeElement, elements[0]);
        }
    }

    public get impresum() {
        // get selected language and replace '-' with '_' so that key can match with links.
        this.transformLanguage = this.translate.currentLang.replace("-", "_");

        // if value in appLInks is null/undefined fallback to en_US.
        if (this.appConfig.appLinks["impresum_" + this.transformLanguage])
            return this.appConfig.appLinks[
                "impresum_" + this.transformLanguage
            ];

        return this.appConfig.appLinks["impresum"];
    }

    public isInViewerOrCompare() {
        return (
            window.location.toString().includes("/viewer") ||
            window.location.toString().includes("/compare")
        );
    }
    public isInViewer() {
        return window.location.toString().includes("/viewer");
    }
    // Public Functions \\

    public get isAuthenticated() {
        return this.oAuth.hasValidIdToken();
    }

    public get userName() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        return `${base.firstName} ${base.lastName}`;
    }

    private get userId() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        return base.accountId;
    }

    private get userEmail() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        return base.eMail;
    }

    // Private Functions \\

    private configureOAuth() {
        this.oAuth.configure(authConfig);
        this.oAuth.setupAutomaticSilentRefresh({}, "id_token");
        this.oAuth
            .loadDiscoveryDocument(discoverDocumentOverride)
            .then(() => this.oAuth.tryLoginCodeFlow())
            .then((res) => {
                const path = window.location.pathname.replace("/", "");
                this.debug.log("redirect from path: | " + path + " |end");

                this.routeAfterLogin(path);
            });
    }

    private routeAfterLogin(path: string): void {
        if (this.hasDMTFlags() && path !== "recommendations") {
            const { sessionId, frameId } = this.getDMTFlags();
            this.router.navigate([`viewer/${sessionId}`], {
                queryParams: { frameId, from: "recommendation" },
            });
            this.clearDMTFags();
        } else if (path === "landing") {
            if (this.oAuth.hasValidIdToken()) {
                this.logLogin();
            }
            this.router.navigate(["profile"]);
        }
    }

    private logLogin(): void {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        this.stats.log({
            type: InteractionType.USER_LOGIN,
            userId: base.accountId,
            createdAt: new Date(Date.now()),
        } as LoginInteraction);
    }

    // Event Handler \\

    onLogin() {
        this.oAuth.initLoginFlow();
    }

    onRegister() {
        this.oAuth
            .loadDiscoveryDocument(discoverDocumentOverrideSignUp)
            .then(() => {
                this.oAuth.initImplicitFlow();
            });
    }

    onLogout() {
        this.gtm.pushEvent(GTMCustomEvents.hamburger_logout);
        this.appIn.clearUserId();
        this.oAuth.logOut();
        localStorage.removeItem(this.LAST_ACTION_STORE_KEY);
    }

    onContactSupport(
        targetSupport: "ecp" | "zeiss",
        session?: AvatarCreationSession
    ) {
        this.debug.log("session with: ", targetSupport, session);
        if (window.innerWidth >= 601) {
            this.dialogHost.open(FAQPageComponent, {
                width: "80vw",
                autoFocus: false,
            });
        } else {
            this.dialogHost.open(FAQPageComponent, {
                maxWidth: "100wv",
                width: "95vw",
                autoFocus: false,
            });
        }
    }

    changeLanguage() {
        this.dialogHost.open(LanguageSelectionDialogComponent, {
            width: "fit-content",
            maxWidth: "99vw",
            autoFocus: false,
        });
    }

    public openIntro() {
        if (window.innerWidth > 601) {
            this.dialogHost.open(IntroDialogComponent, {
                width: "60vw",
                height: "80vh",
                autoFocus: false,
                panelClass: "intro-dialog",
            });
        } else {
            this.dialogHost.open(IntroDialogComponent, {
                width: "80vw",
                height: "90vh",
                autoFocus: false,
                panelClass: "intro-dialog",
            });
        }
    }

    public openDataPrivacyDialog() {
        if (
            window.innerWidth > 639 &&
            window.innerWidth <= 979 &&
            window.innerWidth > window.innerHeight
        ) {
            this.dialogHost.open(PrivacyDialogComponent, {
                width: "80vw",
                maxHeight: "90vh",
                autoFocus: false,
            });
        } else if (window.innerWidth > 601) {
            this.dialogHost.open(PrivacyDialogComponent, {
                width: "50vw",
                maxHeight: "90vh",
                autoFocus: false,
            });
        } else {
            this.dialogHost.open(PrivacyDialogComponent, {
                width: "99vw",
                height: "85vh",
                autoFocus: false,
            });
        }
    }

    onChangeRoute(route: string) {
        switch (route) {
            case "profile":
                if (
                    this.isInViewer() &&
                    (this.factory.isInitialized$.getValue() && this.factory.isPaused$.getValue() === false)
                ) {
                    this.screenshotFromAngel(0.9);
                }

                this.router.navigate(["profile"]);
                break;
        }
    }

    private hasDMTFlags(): boolean {
        const sessionFlag = localStorage.getItem(SESSIONID_FLAG);
        const frameFlag = localStorage.getItem(FRAMEID_FLAG);

        return sessionFlag != null && frameFlag != null;
    }

    private getDMTFlags(): { sessionId: string; frameId: string } {
        const sessionId = localStorage.getItem(SESSIONID_FLAG);
        const frameId = localStorage.getItem(FRAMEID_FLAG);
        return { sessionId, frameId };
    }

    private clearDMTFags(): void {
        localStorage.removeItem(SESSIONID_FLAG);
        localStorage.removeItem(FRAMEID_FLAG);
    }

    private async screenshotFromAngel(quality: number = 0.8) {

        const cameraSettings = this.factory.createCameraSettings() as DualCameraSettings;
        const orbit: CameraOrbitParams = { distance: cameraSettings.avatar.distance.initial, yaw: cameraSettings.avatar.yaw?.initial, pitch: cameraSettings.avatar.pitch.initial };
        const takeScreenshotProperties: TakeScreenshotProps={resolution:null,mimeType:"image/jpeg",fov:cameraSettings.avatar.fov,orbit:orbit}
        const session = { ...this._session.selectedSession$.getValue() };

        if (!this.factory.createNewViewerWidget().isAvatarVisible()) {
            this.factory.createNewViewerWidget().setAvatarVisible(true);
            if(this._session.selectedSession$.getValue().source === AvatarSource.CAMERON){
                await this.factory.createNewViewerWidget().takeScreenshot(takeScreenshotProperties);
            }
        }

        if (true) {
            const image = await this.factory
                .createNewViewerWidget()
                .takeScreenshot(takeScreenshotProperties);
            return await this.getBlobFromImageData(image, quality, session.id);
        }
    }

    private getBlobFromImageData(
        // image: ImageData,
        blob: Blob,
        quality,
        sessionId: string
    ): Promise<void> {
        // return new Promise((resolve) => {
        return new Promise(async (resolve) => {
            try  {
                // const { width, height } = image;

                // const canvas = this.renderer.createElement("canvas");

                // canvas.width = width;
                // canvas.height = height;

                // const ctx = canvas.getContext("2d");
                // ctx.putImageData(image, 0, 0);

                // canvas.toBlob(
                //     async (blob) => {
                        // try {
                            const blobUrl = URL.createObjectURL(blob);
                            await this._datacache.setCacheEntryAsync(
                                sessionId,
                                BinaryType[BinaryType.AVATAR_THUMBNAIL],
                                blobUrl
                            );
                            await this._datacache
                                .setAvatarThumbnail(sessionId, blob)
                                .toPromise();
                        // } catch (error) {}

                        // resolve(void 0);
                //     },
                //     "image/jpeg",
                //     quality
                // );
            } catch (error) {
                this.debug.log(error);
            } finally {
                resolve(void 0);
            }
        });
    }
}
