<!-- Left Slider -->
<mat-menu [class]="'nose_ud_menu_left'" hasBackdrop="true" [backdropClass]="'nose_ud_menu-back'"
    #sliderMenuLeft="matMenu">
    <div class="slider-wrapper position-slider" (click)="$event.stopPropagation()">
        <div class="frame-icon-wrapper">
            <mat-icon>keyboard_arrow_up</mat-icon>
            <i class="icon visucloud-icons frame-square"></i>
        </div>
        <app-vcld-slider [customClasess]="'custom-slider'" (click)="$event.stopPropagation()" [options]="verticalSliderOptions" [value]="sliderLeftValueVertical" [direction]="'vertical'" (userValueChange)="onInputChange($event, 'left')" (valueChange)="onLeftSliderValueChange($event, 'left')"></app-vcld-slider>
        <div class="frame-icon-wrapper">
            <i class="icon visucloud-icons frame-square"></i>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
    </div>

    <div class="slider-wrapper rotation-slider" (click)="$event.stopPropagation()">
       <div class="frame-icon-wrapper">
            <mat-icon>undo</mat-icon>
            <i class="icon visucloud-icons frame-square"></i>
       </div>

       <app-vcld-slider [customClasess]="'custom-slider'" class="vertical-slider" (click)="$event.stopPropagation()" [options]="horizontalSliderOptions" [value]="sliderLeftValueHorizontal" [direction]="'horizontal'" (userValueChange)="onInputChange($event, 'left')" (valueChange)="onLeftSliderValueChange($event, 'left')"></app-vcld-slider>
        <div class="frame-icon-wrapper">
            <mat-icon>redo</mat-icon>
            <i class="icon visucloud-icons frame-square"></i>
        </div>
    </div>
</mat-menu>

<!-- Right Slider -->
<mat-menu [class]="'nose_ud_menu_right'" hasBackdrop="true" [backdropClass]="'nose_ud_menu-back'"
    #sliderMenuRight="matMenu">

    <div class="slider-wrapper position-slider" (click)="$event.stopPropagation()">
        <div class="frame-icon-wrapper">
            <mat-icon>keyboard_arrow_up</mat-icon>
            <i class="icon visucloud-icons frame-square"></i>
        </div>

        <app-vcld-slider [customClasess]="'custom-slider'" (click)="$event.stopPropagation()" [options]="verticalSliderOptions" [value]="sliderRightValueVertical" [direction]="'vertical'" (userValueChange)="onInputChange($event, 'right')" (valueChange)="onRightSliderValueChange($event, 'right')"></app-vcld-slider>
        <div class="frame-icon-wrapper">
            <i class="icon visucloud-icons frame-square"></i>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
    </div>

    <div class="slider-wrapper rotation-slider" (click)="$event.stopPropagation()">
        <div class="frame-icon-wrapper">
            <mat-icon>undo</mat-icon>
            <i class="icon visucloud-icons frame-square"></i>
        </div>

        <app-vcld-slider [customClasess]="'custom-slider'" class="vertical-slider" (click)="$event.stopPropagation()" [options]="horizontalSliderOptions" [value]="sliderRightValueHorizontal" [direction]="'horizontal'" (userValueChange)="onInputChange($event, 'right')" (valueChange)="onRightSliderValueChange($event, 'right')"></app-vcld-slider>
        <div class="frame-icon-wrapper">
            <mat-icon>redo</mat-icon>
            <i class="icon visucloud-icons frame-square"></i>
        </div>
    </div>
</mat-menu>

<div class="fake-background" (window:orientationchange)="setCameraforGlasses()">
    <div class="hideable close-btn">
        <button mat-fab class="circle transparent" (click)="close()">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
    </div>

    <div class="hideable avatar-toggle-btn" *ngIf="((rightFrameSizeError != true) || (leftFrameSizeError != true)) && !isLoading">
        <button mat-button [disableRipple]="true" class="vis-dis" (click)="toggleAvatarVisibility()">
            <p>{{ "pages.viewer.frame_only" | translate }}</p>
            <mat-icon>{{isAvatarVisible() ? "toggle_off" : "toggle_on"}}</mat-icon>
        </button>
    </div>

    <div class="row">
        <div class="col xl12 l12 m12 s12 static-wrapper" id="compareContainer">
            <div class=" col s12 loading" *ngIf="isLoading">
                <div class="gradient" *ngIf="initalLoading">
                    <vcld-loading-dots></vcld-loading-dots>
                    <p class="col s4 offset-s4" style="text-align:center; margin-top: 12px;">
                        {{ 'pages.compare.loading' | translate }}
                    </p>
                </div>
            </div>
            <!-- Left Viewer instance -->
            <div class="col xl6 l6 m6 s12 instance-wrapper left">
                <!-- Frame Info Display  -->
                <div class="frame-info-wrapper" *ngIf="leftFrame$ | async as frame" vcldAutomationId="consr_vto_vis-compare-page_frame-info-in-compare-view_details">
                    <p vcldTooltip style="font-weight: bold">{{ frame?.brand || "" }}</p>
                    <p vcldTooltip *ngIf="(_viewer.ecpSettings$ | async)?.modelNameEnabled">{{ frame?.model || "" }}</p>
                    <p *ngIf="selectedCoatingLeft" class="selected-lenses"
                        matTooltipClass="compare-view-tooltip" matTooltip="{{ selectedCoatingLeft | tintCoatingName: translationObjName }}">
                         {{ selectedCoatingLeft | tintCoatingName: translationObjName }}
                        </p>
                    <p *ngIf="selectedTintLeft" class="selected-lenses"
                        matTooltipClass="compare-view-tooltip" matTooltip="{{_tintsCoatings.getTintUIName(selectedTintLeft)}} {{_tintsCoatings.getTintUIValue(selectedTintLeft)}}">
                         {{_tintsCoatings.getTintUIName(selectedTintLeft)}} {{_tintsCoatings.getTintUIValue(selectedTintLeft)}}</p>

                    <div class="only-mobile" *ngIf="!isLoading && (leftFrameSizeError != true)">
                        <button class="nose-slider-trigger" mat-icon-button [disabled]="!isAvatarVisible()"
                            [matMenuTriggerFor]="sliderMenuLeft">
                            <i class="icon visucloud-icons nose-slider"></i>
                        </button>
                    </div>
                </div>

                <!-- Viewer Instance -->
                <div id="viewerWrapperLeft" class="col xl12 l12 m12 s12 viewer"></div>

                <!-- Left select button -->
                <div class="center-order-btn" *ngIf="!isLoading">
                    <button mat-flat-button color="primary"
                        class="rounded order-btn" (click)="switchToOrderLeft()">
                        {{ "pages.viewer.select_frame" | translate }}
                        <i class="icon visucloud-icons next"></i>
                    </button>
                </div>
                <button mat-flat-button color="primary" *ngIf="!isLoading"
                    class="rounded order-btn-mobile only-mobile float-left" (click)="switchToOrderLeft()">
                    {{ "pages.viewer.select_frame_mobile" | translate }}
                    <i class="icon visucloud-icons next"></i>
                </button>

                <!-- Viewer Controls Footer -->
                <div class="viewer-footer hide-on-landscape">
                    <button class="nose-slider-trigger" mat-icon-button *ngIf="!isLoading && (leftFrameSizeError != true)"
                        [disabled]="!isAvatarVisible()" [matMenuTriggerFor]="sliderMenuLeft">
                        <i class="icon visucloud-icons nose-slider"></i>
                    </button>
                </div>
            </div>

            <!-- Right Viewer instance -->
            <div class="col xl6 l6 m6 s12 instance-wrapper right">

                <!-- Frame info Display -->
                <div class="frame-info-wrapper" vcldAutomationId="consr_vto_vis-compare-page_frame-info-in-compare-view_details" *ngIf="rightFrame$ | async as frame">
                    <p vcldTooltip style="font-weight: bold">{{ frame?.brand || "" }}</p>
                    <p vcldTooltip *ngIf="(_viewer.ecpSettings$ | async)?.modelNameEnabled">{{ frame?.model || "" }}</p>
                    <p *ngIf="selectedCoatingRight" class="selected-lenses"
                        matTooltipClass="compare-view-tooltip" matTooltip="{{ selectedCoatingRight | tintCoatingName: translationObjName }}">
                         {{ selectedCoatingRight | tintCoatingName: translationObjName }}
                        </p>
                    <p *ngIf="selectedTintRight" class="selected-lenses"
                        matTooltipClass="compare-view-tooltip" matTooltip="{{_tintsCoatings.getTintUIName(selectedTintRight)}} {{_tintsCoatings.getTintUIValue(selectedTintRight)}}">
                         {{_tintsCoatings.getTintUIName(selectedTintRight)}} {{_tintsCoatings.getTintUIValue(selectedTintRight)}}</p>

                    <div class="only-mobile" *ngIf="!isLoading && (rightFrameSizeError != true)">
                        <button class="nose-slider-trigger" mat-icon-button [disabled]="!isAvatarVisible()"
                            [matMenuTriggerFor]="sliderMenuRight">
                            <i class="icon visucloud-icons nose-slider"></i>
                        </button>
                    </div>
                </div>

                <!-- Viewer Instance -->
                <div id="viewerWrapperRight" class="col xl12 l12 m12 s12 viewer"></div>

                <!-- Right select button -->
                <div class="center-order-btn" *ngIf="!isLoading">
                    <button mat-flat-button color="primary"
                        class="rounded order-btn" (click)="switchToOrderRight()">
                        {{ "pages.viewer.select_frame" | translate }}
                        <i class="icon visucloud-icons next"></i>
                    </button>
                </div>
                <button mat-flat-button color="primary" *ngIf="!isLoading"
                    class="rounded order-btn-mobile only-mobile float-right" (click)="switchToOrderRight()">
                    {{ "pages.viewer.select_frame_mobile" | translate }}
                    <i class="icon visucloud-icons next"></i>
                </button>

                <!-- Viewer Controls Footer -->
                <div class="viewer-footer hide-on-landscape">
                        <button class="nose-slider-trigger" mat-icon-button *ngIf="!isLoading && (rightFrameSizeError != true)"
                            [disabled]="!isAvatarVisible()" [matMenuTriggerFor]="sliderMenuRight">
                            <i class="icon visucloud-icons nose-slider"></i>
                        </button>
                </div>
            </div>
            

        </div>
        <div class="daylight-wrapper" *ngIf="(sunSliderLeft || sunSliderRight) && !isLoading">
            <div class="only-mobile" *ngIf="!isLoading">
                <button class="nose-slider-trigger" mat-icon-button (click)="showSunSlider = !showSunSlider; $event.stopPropagation()">
                    <i class="visucloud-icons sun"></i>
                </button>

                <div class="slider-wrapper adaptive-slider daylight-controls" (click)="$event.stopPropagation()" *ngIf="showSunSlider">
                    <div class="icon-wrapper">
                        <i class="visucloud-icons cloud"></i>
                    </div>
                    <input type="range" class="sunslider" [(ngModel)]="sunsliderValue" (input)="onSunSliderInputChange($event)" #sunslider2 />
                    <div class="icon-wrapper">
                        <i class="visucloud-icons sun"></i>
                    </div>
                </div>
            </div>
            <div class="daylight-controls">
                <div class="icon-wrapper">
                    <i class="visucloud-icons cloud"></i>
                </div>
                <input type="range" class="sunslider" [(ngModel)]="sunsliderValue" (input)="onSunSliderInputChange($event)" #sunslider />
                <div class="icon-wrapper">
                    <i class="visucloud-icons sun"></i>
                </div>
            <div class="daylight-controls"></div>
        </div>
    </div>
</div>