import { Injectable } from '@angular/core';
import { DebugService } from '../../services/debug.service';
import { DataService, DataType} from './data.service';
import { ConsumerAppEnvironment as environment } from "visenvironment";
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { LensType, TintsAndCoatingResponse, TintObj, CoatingObj, COATING_TYPE, TINT_TYPE, TranslationGroup, TintDefinition } from "../../configs/lensSettings.mock";
import { Frame } from '../models/frame.model';
import { AvatarCreationSession, FavoriteFrame } from '../models/avatarcreationsession';
import { TranslateService } from '@ngx-translate/core';
import { Color, LensSettings } from '@vision/3d-viewer';
import { Color as DynamicColor } from "ta-color";

@Injectable({providedIn: 'root'})

export class TintsCoatingsService {

    public tintsCoatingsCatalogue$:BehaviorSubject<TintsAndCoatingResponse|null> = new BehaviorSubject<TintsAndCoatingResponse|null>(null); 
    public isTintsAndCoatingLoaded$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
    public selectedTint$: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
    public selectedCoating$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    private tintsAndCoatingData: TintsAndCoatingResponse = [] as any;
    public translationObjName: string = 'localization';
    defaultColor: Color = { r: 0, g: 0, b: 0};
    defaultSettingsObj = {
        lensWeight: this.defaultColor,
        lensWeightTop: this.defaultColor,
        antiReflectCoatingReflectivity: this.defaultColor,
        antiReflectCoatingReflectivity90: this.defaultColor,
        antiReflectCoatingPower: 0,
        opacity: 0,
        opacityTop: 0,
        isMirror: false
    } as LensSettings;

    constructor (
        private _debug: DebugService,
        private _datacache:DataService,
        private translate: TranslateService
        ) {}

    public async getTintsAndCoatings() {
        this.tintsAndCoatingData = await this._datacache.getData(DataType.TINTS_COATINGS,`${environment.connectivity.tintsAndCoatingEndpoint}`);
        this.tintsCoatingsCatalogue$.next(this.tintsAndCoatingData);
        this.isTintsAndCoatingLoaded$.next(true);
    }
    
    public getTintByCatalogCode(catalogCode: string): TintObj |undefined{        
        let c = this.getAllTints().find((t) => t.catalogCode === catalogCode);
        this.selectedTint$.next(catalogCode);    
        return c;
    }
    
    public getAllLenses(): Array<CoatingObj> {
        if (this.tintsAndCoatingData['coatings'])
            return this.tintsAndCoatingData['coatings'];
        return [];
    }
    public getAllTints(): Array<TintObj> {
        if (this.tintsAndCoatingData['tints'])
            return this.tintsAndCoatingData['tints'];
        return [];
    }
    public getTintGroup(): Array<TranslationGroup> {
        if (this.tintsAndCoatingData['tintsGroup'])
            return this.tintsAndCoatingData['tintsGroup'];
        return [];
    }

    public getCoatingGroup(): Array<TranslationGroup> {
        if (this.tintsAndCoatingData['coatingsGroup'])
            return this.tintsAndCoatingData['coatingsGroup'];
        return [];
    }

    public getTintFamilies(): Array<TranslationGroup> {
        if (this.tintsAndCoatingData['tintsFamilies'])
            return this.tintsAndCoatingData['tintsFamilies'];
        return [];
    }
    public getLensByCatalogCode(catalogCode: string): CoatingObj|undefined {
        if (catalogCode == 'duravision_platinum')
            catalogCode = this.getDefaultCoatingId('platinum');
        this.selectedCoating$.next(catalogCode);
        return this.getAllLenses().find((l) => l.catalogCode === catalogCode);
    }
    public getDefaultCoatingId(name: string) {
        if (!name)
            return '';

        const coatings = this.getAllLenses();
        let defaultCoating = coatings.find((coating) => coating.coatingGroupId === 'DURAVISION' && coating.localization['en-US'].toLowerCase() === name.toLowerCase());

        if(defaultCoating)
            return defaultCoating.catalogCode;

        return '';
    }

    updateWithTintAndCoating(frames: Frame[], session: AvatarCreationSession): Frame[] {
        if (!frames || frames.length === 0)
            return [];
        return this.updateFrames(frames, session?.favoritedFrames);
    }

    updateFrames(frames: Frame[], favoritedFrames: FavoriteFrame[]): Frame[] {
        if (!frames || frames.length === 0) return [];

        if (!favoritedFrames || favoritedFrames.length === 0) return [];

        const frameStore = new Map<string, Frame>();
        const favorites = [] as Frame[];

        favoritedFrames.forEach(frame => {
            const frameObj = this.mapTintAndCoating(frames, frame, frameStore);
            if (frameObj) {
                favorites.push(frameObj);
            }
        })

        return favorites;
    }

    mapTintAndCoating(frames: Frame[], frame: FavoriteFrame, frameStore?: Map<string, Frame>): Frame {
        let frameObj = {} as Frame;
        if (frameStore && frameStore.has(frame.frameId)) {
            const storedFrame = frameStore.get(frame.frameId);
            frameObj = Object.assign({}, storedFrame);
            frameObj.tint = null;
            frameObj.coating = null;
        } else {
            const filteredFrame = frames.filter(x =>x.id === frame.frameId)[0];
            frameObj = Object.assign({}, filteredFrame);
            frameObj.tint = null;
            frameObj.coating = null;

            if (frameStore)
                frameStore.set(frameObj.id, frameObj);
        }

        if (frame?.tintId) {
            frameObj.tint = this.getTintByCatalogCode(frame.tintId);
        } else {
            frameObj.tint = null;
        }

        if (frame?.coatingId) {
            frameObj.coating = this.getLensByCatalogCode(frame.coatingId);
        } else {
            frameObj.coating = null;
        }

        return frameObj;
    }
    // get name of tint/coating using selected language.
    public getNameByLang(key: string, item: any, selectedLang: string): string {
        if (item) {
            if (item[key]) {
                const translations = item[key];
                if (translations[selectedLang]) {
                    return translations[selectedLang];
                } else {
                    return translations['en-US'] || translations['en'];
                }
            }
        }
        return item.coatingGroupId ? item.coatingGroupId : "-";
    }

    
    public getTintUIName(tint: TintObj) {
        if(tint)  return this.getName(this.translationObjName, tint, this.translate.currentLang);
    }

    /**
     * Tint groups matching below condition -> [ Action, Skylet, UniColor, one of the ProGolf ]
     */
    public isUniColorType(tint: TintObj) {
        const values = [tint.settings.topColor.lowestAbsorption, tint.settings.bottomColor.lowestAbsorption, 
                        tint.settings.topColor.highestAbsorption, tint.settings.topColor.highestAbsorption];

        return new Set(values).size == 1; // values.every( (val, i, arr) => val === arr[0] )
    }
 
    /**
     * Tint groups matching below condition -> [ AdaptiveSun_Solid, PhotoFusion ]
     */
    public isPhotoFusionType(tint: TintObj) {
        return (tint.settings.topColor.lowestAbsorption === tint.settings.bottomColor.lowestAbsorption) 
                && (tint.settings.topColor.highestAbsorption === tint.settings.bottomColor.highestAbsorption);
    }

    /**
     * Tint groups matching below condition -> [ AdaptiveSun_Gradient ]
     */
    public isAdaptiveSunGradientType(tint: TintObj) {
        return ((tint.settings.topColor.highestAbsorption === tint.settings.bottomColor.highestAbsorption) 
                && (tint.settings.topColor.lowestAbsorption > tint.settings.bottomColor.lowestAbsorption));
    }

    /**
     * Tint groups matching below condition -> [ BiColor, Gradient, (ProGolf > Gradient)]
     */
    public isGradientType(tint: TintObj) {
        return (tint.settings.topColor.lowestAbsorption === tint.settings.topColor.highestAbsorption) 
                && (tint.settings.bottomColor.highestAbsorption === tint.settings.bottomColor.lowestAbsorption) 
                && (tint.settings.topColor.lowestAbsorption > tint.settings.bottomColor.lowestAbsorption) 
                && (tint.settings.topColor.highestAbsorption> tint.settings.bottomColor.highestAbsorption);
    }

    public getTintUIValue(tint: TintObj) {
        if (this.isUniColorType(tint)) {
                return `${tint.settings.bottomColor.lowestAbsorption}%`;
        } else if (this.isPhotoFusionType(tint)) {
            return `${tint.settings.bottomColor.lowestAbsorption} - ${tint.settings.topColor.highestAbsorption}%`;
        } else if (this.isAdaptiveSunGradientType(tint)) {
            return `${tint.settings.topColor.lowestAbsorption}/${tint.settings.bottomColor.lowestAbsorption} - ${tint.settings.topColor.highestAbsorption}%`;
        } else if (this.isGradientType(tint)) {
            return `${tint.settings.topColor.lowestAbsorption}/${tint.settings.bottomColor.lowestAbsorption}%`;    
        }
    }

    public getSelectedCoatingType(coatingType: LensType): string {
        return LensType[coatingType]?.replace("_", " ");
    }

    public getCoatingIcon(lens?: CoatingObj): string {
        if(lens) {
            if(lens.coatingGroupId === COATING_TYPE.Duravision){
                return `visucloud-icons dura-vision`;
            }
            let name = this.getName(this.translationObjName, lens, 'en-US');
            name = name.replace(/ /g,'');
            const color = name.toLowerCase();
            const classes = `visucloud-icons coating-${color}`;
            return classes;
        }
        return null;
    }

    public getTintIcon(tint: TintObj): string {
        if(tint){
            let name = this.getName(this.translationObjName, tint, 'en-US');
            name = name.replace(/ /g,'-');
            const classes = `visucloud-icons tint-${tint.vcldTintGroupId.replace('_', '-').toLowerCase()}-${name.toLowerCase()}`;
            return classes;
        }
        return null;
    }

    public getTintUnicolorIcon(tint: TintObj, index: number, family: string): string {
        if(tint){
            let name = this.getName(this.translationObjName, tint, 'en-US');
            name = name.replace(/ /g,'-');
            const classes = `visucloud-icons tint-${tint.vcldTintGroupId.replace('_', '-').toLowerCase()}-${name.toLowerCase()} tint-${family.toLowerCase()}-${index}`;
            return classes;
        }
        return null;
    }
     
    public getTintByGroupId(tint: TintObj): string {
        if (tint?.vcldTintGroupId === TINT_TYPE.Gradient || tint?.vcldTintGroupId === TINT_TYPE.UniColor) {
            const tintList = this.getAllTints();
            if (tintList) {
                const tintsByGroup = tintList.filter(x => x.vcldTintGroupId === tint?.vcldTintGroupId);
                const index = tintsByGroup?.findIndex(x => x.catalogCode === tint?.catalogCode);
                if (index > -1) {
                    return this.getTintUnicolorIcon(tint, index, tint?.vcldTintGroupId);
                } 
            }
        } else {
            return this.getTintIcon(tint);
        }

        return '';
    }

    public getName(key: string, item: any, selectedLang: string) {
        return this.getNameByLang(key, item, selectedLang);
    }
    
    public getlensSettingsByCatalogCode(catalogCode: string): LensSettings {
        if (catalogCode == 'duravision_platinum')
            catalogCode = this.getDefaultCoatingId('platinum');

        const coating = this.getAllLenses().find((l) => l.catalogCode === catalogCode);
        const result = this.mergeTintAndCoating(coating);
        return result;

        // return mockedLensSettings[0].settings;
    }

    

    public mergeTintAndCoating(coating: CoatingObj): LensSettings {
        if (!coating) return null;

        if (coating.defaultTint) {
            const tintSettings = this.getTintByCatalogCode(coating.defaultTint)?.settings;

            if (tintSettings) {
                const copiedLensSettings = Object.assign({}, coating.settings);
                const tt = tintSettings.topColor;
                const tb = tintSettings.bottomColor;

                copiedLensSettings.lensWeightTop = { r: tt.r, g: tt.g, b: tt.b };
                copiedLensSettings.lensWeight = { r: tb.r, g: tb.g, b: tb.b };

                copiedLensSettings.opacity = tintSettings.bottomColor.lowestAbsorption;
                copiedLensSettings.opacityTop = tintSettings.topColor.lowestAbsorption;
                return copiedLensSettings;
            }
        }

        const initSettings = coating.settings || this.defaultSettingsObj;

        let settings = Object.assign({}, initSettings);
        settings.lensWeight = this.defaultSettingsObj.lensWeight;
        settings.lensWeightTop = this.defaultSettingsObj.lensWeightTop;

        return settings;
    }

    private getGradientPartStyle(tint: TintObj): string {

        const top: string = this.getTopLowerAbsorptionRGBAColor(tint.settings.topColor).rgba;
        const bottom: string = this.getBottomLowerAbsorptionRGBAColor(tint.settings.bottomColor).rgba;
        return `linear-gradient(${top}, ${top} 50%, ${bottom})`;
    }

    private getPhotochromicPartStyle(tint: TintObj): string {
        if (!this.isPhotochromic(tint)) {
            return "";
        }

        return `linear-gradient(to right, \
                                ${DynamicColor.Transparent.rgba} 50%, \
                                ${this.getTopHighestAbsorptionRGBAColor(tint.settings.topColor).rgba} 50%),`;
    }

    /**
     * Determines if the tint has a photochromic character
     * @returns {boolean}
     */
    public isPhotochromic(tint: TintObj): boolean {
        return (tint.settings.bottomColor.lowestAbsorption != tint.settings.bottomColor.highestAbsorption) || (tint.settings.topColor.lowestAbsorption != tint.settings.topColor.highestAbsorption);
    }

    private getBottomLowerAbsorptionRGBAColor(bottomColor: TintDefinition): DynamicColor {
        return DynamicColor.fromRGBA(Math.floor(bottomColor.r * 255), Math.floor(bottomColor.g * 255), Math.floor(bottomColor.b * 255), bottomColor.lowestAbsorption / 100);
    }

    private getTopLowerAbsorptionRGBAColor(topColor: TintDefinition): DynamicColor {
        return DynamicColor.fromRGBA(Math.floor(topColor.r * 255), Math.floor(topColor.g * 255), Math.floor(topColor.b * 255), topColor.lowestAbsorption / 100);
    }

    private getTopHighestAbsorptionRGBAColor(topColor: TintDefinition): DynamicColor {
        return DynamicColor.fromRGBA(Math.floor(topColor.r * 255), Math.floor(topColor.g * 255), Math.floor(topColor.b * 255), topColor.highestAbsorption / 100);
    }

    /**
     * Creates the CSS string to draw the gradients for the thumbnail
     * @returns {string}
     */
    public getThumbnailStyle(tint: TintObj): string {
        // Photochromic style has to be defined first, because it shall get rendered on top.
        return this.getPhotochromicPartStyle(tint) + this.getGradientPartStyle(tint);
    }
}