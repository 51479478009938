import { Injectable } from '@angular/core';
import { DebugService } from '../../services/debug.service';
import { DataService, DataType, HttpOptions} from './data.service'
import { AvatarCreationSession, FavoriteFrame } from '../models/avatarcreationsession';
import { ConsumerAppEnvironment as environment } from "visenvironment";
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { FrameService } from './frame.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})

export class SessionService {

    public sessionsList$: BehaviorSubject<AvatarCreationSession[]> = new BehaviorSubject<AvatarCreationSession[]>(null); 
    public selectedSession$: BehaviorSubject<AvatarCreationSession> = new BehaviorSubject<AvatarCreationSession>(null);   
    public singleOptician$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);   
    public preSelectedSession$: ReplaySubject<AvatarCreationSession> = new ReplaySubject<AvatarCreationSession>(1);
    public lastUsedSession$: BehaviorSubject<string> = new BehaviorSubject(null);
    private readonly selectedSessionId$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    private readonly sessionBaseURL: string =  environment.connectivity.avatarSessionEndpoint;
    private readonly defaultCustomerNo = "0000620681";
    public showAnnouncementBanner: boolean = true;

    constructor (
        private _debug: DebugService,
        private _datacache:DataService,
        private _http: HttpClient, 
        private _oauth: OAuthService,
        private _translate:TranslateService
        ) {}

    // Download the AvatarCreationSessions for the User and push them to the Cache, return sessionList
    public async fetchSessionsList(requestParams?:HttpOptions) {    
        const sessionsList: AvatarCreationSession[] = await this._datacache.getData(DataType.SESSIONS,this.sessionBaseURL,requestParams);
        this.sessionsList$.next(sessionsList);
        if (sessionsList.length > 1) {
            const optid = sessionsList[0].opticianId;
            const allSame = sessionsList.every(
                (session) => session.opticianId == optid
            );
            if (allSame) {
                this.preSelectedSession$.next(sessionsList[0]);
                this.singleOptician$.next(true);
                this._debug.log(`[CoBranding] preSelectedSession filled with ${sessionsList[0].id}`);
            }
        } else if(sessionsList.length == 1){
            this.singleOptician$.next(true);
            this.preSelectedSession$.next(sessionsList[0]);
            this._debug.log(`[CoBranding] preSelectedSession filled with ${sessionsList[0]?.id}`);
        }
        else
            this._debug.log(`[Session Service] No Sessions found`);

        // Forward to frame download for pre-caching Frames
        sessionsList.forEach((session) => {
            const customerNo = session.opticianCustomerNumber
                ? session.opticianCustomerNumber
                : this.defaultCustomerNo;
                this._datacache.getData(DataType.FRAME_CATALOGUE_OPTICIAN,customerNo);                
                //this.fetchFramesFromCatalogue(customerNo);
                           
        });
    }    

    public async setSession(sessionId: string) {
        if (this.selectedSessionId$.getValue() !== sessionId) {           
            this.selectedSessionId$.next(sessionId);
            const sessions = this.sessionsList$.getValue(); 
            if (sessionId ==null)  
                this.selectedSession$.next(undefined);       
            else if (sessions && sessions.length == 1) 
                this.selectedSession$.next(sessions[0]);                
            else {
                const session = sessions.find((s) => s.id === sessionId);
                if(session==undefined) throw new Error("Session does not exist");
                this.selectedSession$.next(session);   
            }
        }
    }



    public updateSessionLastAccess(sessionId: string, date: Date) {
        this._debug.log("[SessionSerivce] updating the last access value!");
        const uri = `${environment.connectivity.lastAccessEndpoint}/${sessionId}`;

        return this._http
            .put(uri, date, {
                headers: { Authorization: `Bearer ${this._oauth.getIdToken()}` },
            })
            .toPromise()
            .then( async() => {
                this._datacache.invalidateCacheEntry(this.sessionBaseURL,"SESSIONS");
                await this.fetchSessionsList({headers:{'x-re-cache': 'false'}});
            });
    }

    public deleteAvatarSessionById(sessionId: string) {
        const uri = environment.connectivity.deleteAvatarSessionEndpoint.replace('{id}', sessionId.toString());
        return this._http.delete(uri, {
            headers: { Authorization: 'Bearer ' + this._oauth.getIdToken() },
            params: { lang: this._translate.currentLang }
        }).pipe(tap(async() => {
            this._datacache.invalidateCacheEntry(this.sessionBaseURL,"SESSIONS");
        }));
    }

    
}
