import { Injectable } from '@angular/core';
import { DebugService } from '../../services/debug.service';
import { DataService, DataType } from './data.service';
import { SessionService } from './session.service';
import { ViewerFactoryService } from "../services/viewer.factory.service";
import { BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  public ecpSettings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor( 
    private _debug: DebugService,
    private _datacache:DataService,
    private _session:SessionService,
    ) { }

    public async initEcpSettings(){ 
      let opticianId =this._session.selectedSession$.getValue().opticianId;
      if(!opticianId) 
        return of (null);      
      let ecpSettings = await this._datacache.getData(DataType.ECP_SETTINGS,opticianId);
      this.ecpSettings$.next(ecpSettings);
  }
  /*Add following for new viewer here:
  - Initialization State of the Viewer
  - Get Viewer Instance (automatically creates new one if not present)
  - Get Viewer Instances (amount)*/
}
