import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { DebugService } from '../../../services/debug.service';

@Component({
  selector: 'vcld-social-share',
  templateUrl: './social.dialog.component.html',
  styleUrls: ['./social.dialog.component.scss']
})
export class SocialDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private shareData: ShareData & { files: File[] }, private debug: DebugService) { }

  public safeImgSrc: SafeUrl;

  public imageHeight: number;

  public downloadLink: string;

  ngOnInit(): void {
    const s = URL.createObjectURL(this.shareData.files[0]);
    this.safeImgSrc = s;
    // this.imageHeight = `${(Math.floor(window.innerHeight / 2))}px`;
    this.imageHeight = (Math.floor(window.innerHeight / 2));
    // this.debug.log(this.imageHeight, window.innerHeight, Math.floor(window.innerHeight / 2));
  }

  public share() {
    if (navigator.share) {
      navigator.share(this.shareData)
        .then(() => this.debug.log('Share was successful.'))
        .catch((error) => { this.debug.log('Sharing failed', error); });
    }
  }

  public download() {
    // window.open();
  }

  private isIOS = this.debug.iOS_Device();

  public canShare(): boolean {
    return (navigator.share != null && navigator.share != undefined) && !this.isIOS && !this.debug.isIosEdgeBrowser();
  }

  public canDownload(): boolean {
    return !this.debug.isIosEdgeBrowser();
  }
}
